<template>
  <div>
    <div @click="goBack()" class="back">返回</div>
    <div :id="id" class="provinceCharts"></div>
  </div>
</template>

<script>
import {getMap} from "@/api/bigData";
import {monitorData} from "@/api/common";

export default {
  name: "province",
  data() {
    return {
      id: "echarts_" + new Date().getTime() + Math.floor(Math.random() * 1000),
      echartsObj: null,
      provinceName: "",
      dataList: [],
      phase_code: ''
    };
  },
  created() {
    this.phase_code = this.$route.params.phase_code || ''
  },
  async mounted() {
    this.initDate();
    this.resizeListener();
  },
  methods: {
    async initDate() {
      const province = this.$route.query.province;
      const code = this.$route.query.code;
      let options = {
        title: {
          text: province,
          top: "40px",
          left: "10px",
          textStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: "#ffffff",
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>电站数量:{c}'
        },
        visualMap: {
          type: 'piecewise',
          left: 40,
          bottom: 40,
          dimension: 'value',
          pieces: [
            { min: 0, max: 0, label: '0',},
            { min: 1, max: 100, label: '1-100',},
            { min: 101, max: 1000, label: '101-1000',},
            { min: 1001, max: 10000, label: '1001-1000',},
            { gt: 10000, label: '>10000',},
          ],
          inRange: {
            color: [
              '#107495',
              '#1488AF',
              '#179DCA',
              '#1BB1E4',
              '#1FC6FF',
            ],
          },
          textStyle: {
            color: '#FFFFFF'
          },
          itemWidth: 64,
          itemHeight: 24,
          itemSymbol: 'rect',
        },
        geo: {
          map: province,
          zoom: 0.6, //视角缩放比例
          roam: true, //是否开启平游或缩放
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 0.4,
            max: 2, //可以放大几倍
          },
          label: {
            normal: {
              show: true,
              fontSize: 10,
              color: "#fff",
            },
            emphasis: {
              show: true,
              color: '#fff',
            },
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(0, 0, 0, 0.2)",
              areaColor: "#e0f3f8",
            },
            emphasis: {
              areaColor: "skyblue", // 鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          left: "5%",
          right: "5%",
          top: "5%",
          bottom: "5%",
        },
        series: [
          {
            name: "省份数据",
            type: "map",
            geoIndex: 0, // 不可缺少，否则无tooltip 指示效果
            data: [],
          },
        ],
      }
      const mapJson = await getMap(
        `https://geo.datav.aliyun.com/areas_v3/bound/${code}_full.json`
      );
      this.echartsObj = this.$echarts.init(document.getElementById(this.id));

      mapJson.features.forEach(iter => {
        this.dataList.push({
          ename: '',
          name: iter.properties.name,
          code: iter.properties.adcode,
          value: 0,
        })
      })

      const res = await monitorData()
      const { city } = res
      for (let item of city) {
        const target = this.dataList.find(iter => iter.name === item.name)
        if (target) {
          target.value = item.size
        }
      }

      options.series[0].data = this.dataList

      this.$echarts.registerMap(province, mapJson);
      this.echartsObj.setOption(options);
      // let that = this
      // this.echartsObj.on("click", (params) => {
      //   console.log(params, '-------params-');
      //   this.$router.push({
      //     path: "/admin/monitorBoard/city/" + (that.phase_code || 0),
      //     query: {city: params.name, code: params.data.code},
      //   });
      // });
    },
    resizeListener() {
      window.addEventListener("resize", () => {
        if (this.echartsObj && this.echartsObj.resize) {
          this.echartsObj.resize();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.back {
  color: white;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 40px;
  right: 10px;
  transform: translateX(-50%);
  z-index: 999;
  cursor: pointer;
}

.provinceCharts {
  width: 824px;
  height: 932px;
  margin: 0 auto;
}
</style>
